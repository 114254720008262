import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import EntryHeader from "../../components/SingleTemplateComponents/EntryHeader"
import ShareBar from "../../components/SingleTemplateComponents/ShareBar"
import ComponentParser from "../../utils/ComponentParser"
import VideoEmbed from "../../components/SingleTemplateComponents/VideoEmbed"
import AudioPlayer from "react-h5-audio-player"
import PlayIcon from "../../components/svgs/PlayIcon"
import PauseIcon from "../../components/svgs/PauseIcon"

const Conference = ( { data: { conference } } ) => {

	return (
		<Layout>
			<Seo
				title={conference.title}
				canonical={conference.seo?.canonical}
				uri={conference.uri}
				socialImage={conference.featuredImage?.node}
				description={conference.seo?.metaDesc ? conference.seo.metaDesc : conference.excerpt.replace(/(<([^>]+)>)/gi, "") }
			/>


			{ conference?.resourceMeta?.videoEmbed ?
				<div>
					<VideoEmbed 
						video={conference.resourceMeta.videoEmbed}
					/>
				</div>

				:
				<div>
					{ conference?.resourceMeta?.audiofile?.localFile?.publicURL &&
						<AudioPlayer
							src={conference.resourceMeta.audiofile.localFile.publicURL}
							showSkipControls={false}
							showJumpControls={false}
							layout="horizontal"
							customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
						/>
					}
				
					{ ! conference?.resourceMeta?.audiofile?.localFile?.publicURL && conference?.resourceMeta?.audiofile?.mediaItemUrl &&
						<AudioPlayer
							src={conference.resourceMeta.audiofile.mediaItemUrl}
							showSkipControls={false}
							showJumpControls={false}
							layout="horizontal"
							customIcons={ {play: <PlayIcon />, pause: <PauseIcon /> }}
						/>
					}
				</div>
			}
            
			<EntryHeader
				title={conference.title}
				date={conference.date}
				icon={conference?.organizations?.nodes[0]?.organizationMeta?.logo?.localFile }
				format='Conference'
			/>

			<ShareBar 
				url={conference.uri}
			/>

			<div className="max-w-2xl mx-auto mt-4 md:-mt-64 pb-16 px-4 md:px-0 min-h-[40vh]">
				{conference.blocks &&
				conference.blocks.map((block, i) => {
					return (
						<div key={i}>
							<ComponentParser
								block={block}
								page={conference}
							/>
						</div>
					)
				})}
				{ conference.resourceMeta?.sourceUrl &&
						<p className="mt-12">
							This content was originally published on  
							
							<Link 
								to={conference.resourceMeta.sourceUrl}
								target="_blank"
							>
								{conference?.organizations?.nodes[0] ?
									' ' + conference.organizations.nodes[0].name
								: 
									conference.resourceMeta.sourceUrl
								}
							</Link>
						</p>
					}
			</div>
				
		</Layout>
	);
}

export const conferenceQuery = graphql`
	query conferenceById(
		$id: String!
	) {
		conference: wpConference( id: { eq: $id } ) {
			... ConferenceContent
		}
	}
`
export default Conference